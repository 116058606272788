@charset "UTF-8";
.pagination {
  font-weight: 400;
  border: 0; }
  .pagination > .page-item {
    display: inline; }
    .pagination > .page-item > .page-link,
    .pagination > .page-item > span {
      border-radius: 0.25rem;
      margin-left: 2px;
      margin-bottom: 0px;
      margin-right: 0px; }

.pagination {
  font-weight: 400;
  display: inline; }
  .pagination > li {
    display: inline; }
    .pagination > li > a,
    .pagination > li > span {
      border-radius: 3px; }
    .pagination > li > a {
      color: gray; }
    .pagination > li > a:hover {
      color: #555; }

.pagination-legend {
  margin-left: 0rem;
  margin-top: 0.5rem;
  margin-right: 2rem;
  color: gray; }

/*
.pagination ul{
  position: absolute;
  right: 0rem;
  min-height: 2rem;
  margin-top: 0rem;
}
*/
/*
$gray-darker:            lighten(#000, 13.5%) !default; // #222
$gray-dark:              #343434 !default;
$gray:                   lighten(#000, 33.5%) !default; // #555
$gray-light:             lighten(#000, 60%) !default;   // #999
$gray-lighter:           lighten(#000, 93.5%) !default; // #eee
$gray-semi-light:        #777;
$gray-semi-lighter:      #ddd;
*/
.page-item > a {
  background-color: #777;
  border-color: #d7d7d7; }

li.page-item > a:hover {
  background-color: #eee;
  border-color: #bbb; }

/*
.page-link:hover{

  border-color: red ;
}
*/
.page-item.active > a {
  background-color: #ddd;
  border-color: #999; }

.page-item.active > a:hover {
  background-color: #d9d9d9;
  border-color: #777; }

.table-widget thead tr th {
  background-color: white !important;
  border-bottom: 0; }

select.pagination-select {
  border-color: #999; }

#period-container {
  position: absolute;
  background: #fff;
  width: 250px;
  height: 100%;
  z-index: 4;
  border-left: 1px solid #cdcecf;
  right: -251px;
  top: 0px;
  background: #fff;
  transition-duration: .25s;
  -webkit-transition-duration: .25s;
  -moz-transition-duration: .25s;
  -o-transition-duration: .25s;
  -ms-transition-duration: .25s; }
  #period-container *, #period-container *:after, #period-container *:before {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; }
  #period-container input[type='text'] {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; }
  #period-container:hover, #period-container:hover .chat-list-open-close {
    border-color: #a7a7a7; }
  #period-container.open {
    right: 0px;
    top: 0px; }
    #period-container.open .chat-list-open-close i:before {
      content: "\f00d" !important; }
    #period-container.open .chat-list-open-close b {
      display: none; }
  #period-container .chat-list-open-close {
    display: block;
    width: 25px;
    height: 25px;
    border: 1px solid #cdcecf;
    border-right: 1px solid #f2f4f8 !important;
    position: absolute;
    left: -32px;
    top: 7px;
    border-radius: 5px 0 0 5px;
    background: #fff;
    padding: 3px 1px 3px 5px;
    font-size: 21px;
    line-height: 22px;
    cursor: pointer;
    color: #868686;
    box-shadow: inset 0 0.2em 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 0.2em 0 rgba(0, 0, 0, 0.05);
    background: #f2f4f8;
    text-align: left; }
    #period-container .chat-list-open-close b {
      position: absolute;
      right: 0px;
      background: #ed1c24;
      line-height: 9px;
      height: 10px;
      width: 10px;
      font-size: 0;
      top: 3px;
      border: 1px solid #FFF;
      border-radius: 3px;
      font-size: 9px;
      text-align: center;
      vertical-align: middle;
      color: #fff;
      font-weight: normal; }
  #period-container .chat-list-body {
    height: 217px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: #f2f4f8; }
  #period-container #chat-users {
    list-style: none;
    margin: 10px 0;
    padding: 0; }
    #period-container #chat-users li {
      margin-bottom: 3px;
      margin-left: 5px; }
      #period-container #chat-users li a {
        display: block;
        font-size: 13px;
        color: #3d3d3d;
        text-decoration: none !important;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        padding: 3px 8px 3px 3px;
        line-height: 33px;
        vertical-align: middle; }
        #period-container #chat-users li a:hover {
          background: #e0e4ee; }
        #period-container #chat-users li a img {
          width: 33px;
          height: auto;
          margin-right: 10px; }
        #period-container #chat-users li a i {
          color: #acacac;
          font-size: 8px;
          line-height: 34px;
          font-style: normal; }
          #period-container #chat-users li a i.last-online {
            font-size: 12px;
            letter-spacing: -1px; }
  #period-container .chat-list-footer {
    overflow: hidden;
    border-top: 1px solid #CCC;
    padding: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box; }
    #period-container .chat-list-footer input[type="text"] {
      border: 1px solid #bababa !important; }
  #period-container .control-group {
    padding: 0px; }

.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell; }
  .bootstrap-touchspin .input-group-btn-vertical > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 8px 10px;
    margin-left: -1px;
    position: relative; }
  .bootstrap-touchspin .input-group-btn-vertical > .btn1 {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 8px 10px;
    margin-left: -1px;
    position: relative; }
  .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0;
    border-top-right-radius: 4px; }
  .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    margin-top: -2px;
    border-radius: 0;
    border-bottom-right-radius: 4px; }
  .bootstrap-touchspin .input-group-btn-vertical i {
    position: absolute;
    top: 3px;
    left: 5px;
    font-size: 9px;
    font-weight: normal; }
  .bootstrap-touchspin .input-group-btn-vertical button[disabled] {
    color: white; }

.ehis-input {
  height: 30px; }

.ehis-input-button {
  height: 30px;
  width: 30px; }

.smart-form .col-7 {
  width: 58.33%; }

.smart-form .col-12 {
  width: 100%; }

.smart-style-0 nav ul li.open > a, nav ul li.open > a b {
  color: #c0bbb7 !important; }

.smart-style-1 nav ul li.open > a, nav ul li.open > a b {
  color: #8b91a0 !important; }

.smart-style-2 nav ul li.open > a, nav ul li.open > a b {
  color: #69737a !important;
  font-weight: normal !important; }

.btn-table-part {
  color: #070;
  background-image: none;
  background-color: transparent;
  border-color: #070; }
  .btn-table-part:hover {
    color: #fff;
    background-color: #070;
    border-color: #070; }
  .btn-table-part:focus, .btn-table-part.focus {
    color: #fff;
    background-color: #070;
    border-color: #070; }
  .btn-table-part:active, .btn-table-part.active,
  .open > .btn-table-part.dropdown-toggle {
    color: #fff;
    background-color: #070;
    border-color: #070; }
    .btn-table-part:active:hover, .btn-table-part:active:focus, .btn-table-part:active.focus, .btn-table-part.active:hover, .btn-table-part.active:focus, .btn-table-part.active.focus,
    .open > .btn-table-part.dropdown-toggle:hover,
    .open > .btn-table-part.dropdown-toggle:focus,
    .open > .btn-table-part.dropdown-toggle.focus {
      color: #fff;
      background-color: #002000;
      border-color: black; }
  .btn-table-part.disabled:focus, .btn-table-part.disabled.focus, .btn-table-part:disabled:focus, .btn-table-part:disabled.focus {
    border-color: #00dd00; }
  .btn-table-part.disabled:hover, .btn-table-part:disabled:hover {
    border-color: #00dd00; }

.thumb {
  position: relative;
  display: inline-block;
  text-align: center; }
  .thumb img {
    height: auto;
    max-width: 100%;
    vertical-align: middle; }

.widget-chat-list-group {
  padding-top: 0.5rem; }
  .widget-chat-list-group .list-group-item {
    margin-left: 20px;
    margin-right: 20px;
    padding: 0;
    border: 1px;
    border-bottom: 1px;
    margin-top: 20px;
    cursor: pointer; }
    .widget-chat-list-group .list-group-item + .list-group-item {
      margin-top: 40px; }
    .widget-chat-list-group .list-group-item .thumb,
    .widget-chat-list-group .list-group-item .thumb-sm {
      float: left;
      margin-right: 15px; }
    .widget-chat-list-group .list-group-item .time {
      float: right;
      color: #218BC3; }
    .widget-chat-list-group .list-group-item .sender {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 13px;
      font-weight: 400; }
    .widget-chat-list-group .list-group-item .body {
      font-size: 13px; }
    .widget-chat-list-group .list-group-item.on-right .thumb,
    .widget-chat-list-group .list-group-item.on-right .thumb-sm {
      float: right;
      margin-left: 15px;
      margin-right: 0; }
    .widget-chat-list-group .list-group-item.on-right .time {
      float: left; }
    .widget-chat-list-group .list-group-item.on-right .sender {
      text-align: right; }

.form-actions button {
  min-width: 8rem;
  margin-left: 0.25rem; }

/*
.list-group:last-of-type .list-group-item:last-child .dynamic-ehis-selected {
  border: 1px dotted $brand-danger;
}
*/
/*
li.dynamic-ehis {
  border-radius: $border-radius;
  //  border-bottom: 1px;
  //  margin: 3px;
  padding-top: 0;
  padding-bottom: 0;

  .selected {
    border: 1px dotted $brand-danger !important;
  }

  .unselected {
    border: 1px dotted $white !important;
  }
}
*/
/*
.dynamic-ehis-unselected {
  border: 1px dotted $white;
  border-bottom: 1px;
  border-radius: $border-radius;
  //  margin: 3px;
  //  padding: 0;
  .list-group:last-of-type .list-group-item:last-child {
    border-bottom: 1px dotted $white;
  }
}
*/
/***********************************/
/**          FileUpload.css       **/
/***********************************/
.file_upload {
  display: block;
  position: relative;
  overflow: hidden;
  font-size: 1em;
  /* example */
  height: 2em;
  /* example */
  line-height: 2em; }

.file_upload .button, .file_upload > mark {
  display: block;
  cursor: pointer; }

.file_upload .button {
  float: right;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 8em;
  /* example */
  height: 100%;
  text-align: center; }

.file_upload > mark {
  background: transparent;
  /* example */
  padding-left: 1em; }

@media only screen and (max-width: 500px) {
  /* example */
  .file_upload > mark {
    display: none; }
  .file_upload .button {
    width: 100%; } }

.file_upload input[type=file] {
  position: absolute;
  top: 0;
  opacity: 0; }

/* Making it beautiful */
.file_upload {
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.1s linear; }

.file_upload.focus {
  box-shadow: 0 0 5px rgba(0, 30, 255, 0.4); }

.file_upload .button {
  background: #7300df;
  transition: background 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #fff;
  text-shadow: #6200bd 0 -1px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.file_upload:hover .button {
  background: #6200bd;
  text-shadow: #5d00b3 0 -1px 0; }

.file_upload:active .button {
  background: #5d00b3;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) inset; }

.free-label {
  margin-bottom: 0; }

.glyphicon-hide {
  color: transparent; }

.margin-link-value-remove {
  margin-left: 0.75rem;
  padding-top: 0.25rem; }

.margin-link-value-add {
  margin-top: -0.75rem; }

.margin-link-radio-photo {
  margin-left: 0.75rem;
  padding-top: 0.25rem; }

.margin-link-radio-remove {
  margin-left: 0.75rem;
  padding-top: 0.25rem; }

/***********************************/
/**            DYNAMIC            **/
/***********************************/
/*
.dynamic-form-ehis-label {
  color: $brand-danger;
  background-color: #377f7c;
  border: 3px solid $input-border-color;
  border-radius: $border-radius;
}

.dynamic-form-ehis-selected {
  border: 3px dotted $brand-danger;
  border-radius: $border-radius;
  margin: 3px;
  padding: 0;
}

.dynamic-form-ehis-selected>.form-control-sm {
  border: 3px dotted $brand-danger;
  border-radius: $border-radius;
  margin: 3px;
  padding: 0;
}


.dynamic-remove-btn {
  align: right;
}
*/
/*
.margin-link-remove-value {
    margin-left: 0.75rem;
    padding-top: 0.25rem;
}

.margin-link-add-value {
  margin-top: -0.75rem;
}
*/
.margin-link-value-remove {
  margin-left: 0.75rem;
  padding-top: 0.25rem; }

.margin-link-value-add {
  margin-top: -0.75rem; }

.btn-photo {
  background-color: transparent;
  border: 3px dotted #dd5826; }

.table-row {
  display: -webkit-inline-flex;
  /* Safari */
  display: inline-flex;
  /*���������� ��������� ��� �������� �������*/ }

.panel-default {
  border: 0; }

#myTab1
li:not(.active) a, #myTab1
li a:hover {
  color: #3276b1 !important; }

.table-hover tbody tr:hover {
  background-color: #F1DA91; }

.table-hover .import1 {
  color: #fff; }

legend {
  color: #3276b1 !important; }

.table-select tbody tr:hover {
  cursor: pointer; }

/*
.form-control select {
  border-radius: 0;
}

.input-group {
  border-radius: 0.5rem!important;
}

select .form-control{
  height: 20px;
  -webkit-border-radius: 0;
  border: 0;
  outline: 1px solid #ccc;
  outline-offset: -1px;
}

*/
/*
.control-label {
  :after {
    content: "*";
    display: inline-block;
    vertical-align: middle;
  }

}
*/
.control-label:after {
  content: "\2007"; }

.label-required:after {
  content: "*";
  color: #D56161; }

.form-padding-top {
  padding-top: 2.5rem !important; }

.form-horizontal {
  padding-top: 2.5rem !important;
  /*
    footer{
      margin: $line-height-computed 0 0;
      padding: $line-height-computed;
      background-color: #f5f5f5;

  //    .form-horizontal & .row{
      margin-left: ($grid-gutter-width/-2) - 20px;
      margin-right: ($grid-gutter-width/-2) - 20px;

  //    }

    }
  */ }

textarea {
  resize: vertical; }

modal.modal-widget .modal-content {
  background-color: #d5d4da !important;
  /* . {
      opacity: 0!important
    }
*/ }
  modal.modal-widget .modal-content .modal-header {
    display: none !important; }
  modal.modal-widget .modal-content .modal-footer {
    display: none !important; }

modal.modal-widget > .modal-footer {
  /*
    .no-footer {
      *, *:after, *:before {
        display: none !important;
      }
      display: none !important;
    }
    */
  display: none !important; }

modal.modal-widget .modal-body {
  padding: 0 !important; }

route-modal.modal-widget .modal-content {
  background-color: #d5d4da !important;
  /* . {
       opacity: 0!important
     }
 */ }
  route-modal.modal-widget .modal-content .modal-header {
    display: none !important; }
  route-modal.modal-widget .modal-content .modal-footer {
    display: none !important; }

route-modal.modal-widget .modal-body {
  padding: 0 !important; }

.jarviswidget {
  margin-bottom: 0 !important; }

.sp-message-container {
  position: absolute;
  /*  top: 20%;
  left: 0;
  right: 0;
  height: 0;
  text-align: center;
*/
  z-index: 1041;
  /* 10001; */ }

.sp-message {
  /*
  display: inline-block;
  text-align: left;
  background-color: #333;
  color: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  filter: alpha(opacity=100);
  */ }

/*
.form fieldset {
  display: block;
  border: none;
  background: rgba(red($white), green($white), blue($white), 0.9);
  position: relative;
}

fieldset {
  position:relative;
}

*/
/*
$gray-lightest:          lighten($black, 95.5%);
form.form-horizontal {

  fieldset {
    position:relative;
  }

  footer {
    display: block;
    padding: 13px 14px 15px;
    border-top: 1px solid rgba(red($black), green($black), blue($black), 0.1);
    background: rgba(red(lighten($gray-lightest, 2%)), green(lighten($gray-lightest, 2%)), blue(lighten($gray-lightest, 2%)), 0.9);
    margin-top: 25px;
    margin-left: -13px;
    margin-right: -13px;
    margin-bottom: -13px;
    text-align: right;
  }

}
*/
.no-footer div.modal-footer {
  display: none;
  padding: 0 !important; }

.spin {
  margin-top: 1rem;
  margin-left: 1.75rem; }
  .spin i {
    font-size: 24px;
    color: #c79121; }

/*
.fc-view-container .fc-view {
  overflow-x: scroll;
}

.fc-view-container .fc-view > table {
  width: 500%;
}

.td.fc-axis {
  position: fixed;
  background-color: lightgrey;
}
.th.fc-axis{
  position: fixed;
  background-color: lightgrey;
}
*/
/*
.fc-resource-cell {
  width:82px;
}

td.fc-widget-content
{
  width:82px;
}
*/
.widget-content {
  padding: 10px 14px;
  position: relative;
  /* :not(:first-child) {
     margin-top:-20px;
   }
   */
  /*position:relative;


  left:0px;*/ }
  .widget-content .widget-body {
    min-height: 144px; }
  .widget-content footer {
    display: block;
    padding: 7px 14px 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(248, 248, 248, 0.9);
    margin-top: 13px;
    margin-left: -13px;
    margin-right: -13px;
    margin-bottom: -13px;
    text-align: right; }
    .widget-content footer .btn {
      float: right;
      height: 31px;
      margin: 10px 0 0 5px;
      padding: 0 22px;
      font: 300 15px/29px 'Open Sans', Helvetica, Arial, sans-serif;
      cursor: pointer; }
    .widget-content footer:after {
      content: '';
      display: table;
      clear: both; }
  .widget-content .table-widget {
    margin-bottom: -1px; }
  .widget-content .widget-button-row {
    padding: 8px 10px; }
    .widget-content .widget-button-row:empty {
      padding: 0; }
  .widget-content tbody tr:last-child {
    border-bottom-style: solid;
    border-bottom-color: #ddd;
    border-bottom-width: 1px; }
  .widget-content .after-header {
    border-width: 1px 1px 1px 1px; }
  .widget-content .attention {
    color: red; }

.demo {
  width: 189px;
  z-index: 1038; }

nav ul span.menu-item-parent {
  margin-left: 3.5px; }

.widget-content .select2-container {
  z-index: 699; }

th.dt-center, td.dt-center {
  text-align: center; }

th.dt-right {
  text-align: center; }

td.dt-right {
  text-align: right; }

.dt-toolbar {
  border-bottom: 0 !important; }

.dataTables_scrollHeadInner th {
  border: 1px solid #ddd; }

.dataTables_scrollHeadInner tr {
  height: 32px; }

/*
.dataTables_scrollHeadInner {
  width: 100% !important;
}
.dataTables_scrollHeadInner table {
  width: 100% !important;
}
*/
.fixed-left-column {
  /* th:first-child {  */
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  border-left: 0 !important;
  /*    background: #ccc;  */ }

table.sticky-header {
  border-collapse: unset; }

.sticky-header thead tr th {
  position: sticky;
  top: 0;
  border-bottom-width: 1px;
  /* border-bottom-color: green; */
  /* color: black; */
  border-bottom-style: solid;
  border-bottom-color: #CCC;
  z-index: 2; }

.sticky-header thead tr th:first-child {
  left: 0;
  z-index: 3;
  border-right-width: 1px;
  /* border-bottom-color: green; */
  /* color: black; */
  border-right-style: solid;
  border-right-color: #CCC; }

.sticky-header tbody tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  border-right-width: 1px;
  border-right-color: #CCC;
  /* border-bottom-color: green; */
  /* color: black; */
  border-right-style: solid; }

.sticky-header tbody > tr:nth-child(odd) > td:first-child {
  background-color: #f9f9f9; }

.sticky-header tbody > tr:nth-child(even) > td:first-child {
  background-color: white; }

.sticky-header tbody > tr:hover:nth-child(odd) > td:first-child {
  background-color: #ecf3f8 !important; }

.sticky-header tbody > tr:hover:nth-child(even) > td:first-child {
  background-color: #ecf3f8 !important; }

.table.header_dark thead th {
  color: #fff;
  background-color: #696969 !important;
  border-color: #5d5d5d !important; }

.widget_table_wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden; }
  .widget_table_wrapper table.table tbody > tr:hover td {
    background-color: #F1DA91 !important; }
  .widget_table_wrapper table.table tbody > tr:nth-child(odd) td {
    background-color: #f9f9f9; }
  .widget_table_wrapper table.table tbody > tr:nth-child(even) td {
    background-color: #fff; }

/*.table .thead-dark th {

  background-color: #696969;
  border-color: #5d5d5d;
}
*/
.widget_table_fixed_column table {
  border-collapse: unset; }

.widget_table_fixed_column table thead tr th:first-child {
  left: 0;
  border-right-width: 1px;
  border-right-style: solid;
  border-color: #CCC; }

.widget_table_fixed_column_1 table {
  border-collapse: unset; }

.widget_table_fixed_column_1 table thead tr th:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 4;
  border-right: solid 1px #CCC; }

.widget_table_fixed_column_1 table tbody tr td:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 2;
  border-right: solid 1px #CCC; }

.widget_table_fixed_column_2 table {
  border-collapse: unset; }

.widget_table_fixed_column_2 table thead tr th:nth-child(3) {
  position: sticky;
  left: 0;
  z-index: 4;
  border-right: solid 1px #CCC; }

.widget_table_fixed_column_2 table tbody tr td:nth-child(3) {
  position: sticky;
  left: 0;
  z-index: 2;
  border-right: solid 1px #CCC; }

.widget_table_fixed_header table {
  border-collapse: unset; }

.widget_table_fixed_header table thead tr th {
  position: sticky;
  top: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #CCC;
  z-index: 2; }

.widget_table_fixed_header table thead tr th:first-child {
  z-index: 3; }

.widget_table_fixed_column table {
  border-collapse: unset; }
  .widget_table_fixed_column table thead tr th:first-child {
    position: sticky;
    left: 0;
    border-right-width: 1px;
    border-right-style: solid;
    border-color: #CCC; }
  .widget_table_fixed_column table tbody {
    /*
              >tr:hover {
                td {
                  background-color: #ecf3f8!important;
                }
              }


              > tr:nth-child(odd) {
               td {
                  background-color: #f9f9f9;
                }
              }

              > tr:nth-child(even) {
                td {
                  background-color: #fff;
                }
              }
      */ }
    .widget_table_fixed_column table tbody tr td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      border-right-width: 1px;
      border-right-color: #CCC;
      border-right-style: solid; }

#main {
  padding-bottom: 0 !important;
  min-height: auto; }

.widget_table_scrollY {
  overflow-y: scroll !important; }

/*
.select2-selection__clear, .select2-search-choice-close {

  max-height: 20px!important;
  margin-right: 6px!important;
  margin-top:-2px!important;
  margin-left: 6px!important;
  cursor: pointer;

  display: inline!important;
}
*/
.smart-form .select2-selection__rendered {
  margin-left: 9px; }

.col-sm-0 {
  display: none; }

.col-md-0 {
  display: none; }

.navigator .buttons-navigator {
  display: inline; }
  .navigator .buttons-navigator button.btn-navigator {
    padding: 0.5rem 1.46rem;
    /* font-size: 14px; */
    cursor: pointer;
    margin-bottom: 10px;
    width: 8rem; }
    .navigator .buttons-navigator button.btn-navigator.selected {
      color: #333333;
      background-color: #fef3cb;
      border: solid 1px #c0c0c0;
      border-radius: 0; }

.navigator .navigator_default_month {
  font-size: 12px; }

.calendar_default_colheader_inner {
  font-size: 12px; }

.scheduler_default_corner div {
  justify-content: center; }

.cd-container {
  display: flex;
  flex-direction: row; }

.content {
  flex-grow: 1; }

.buttons {
  margin-bottom: 10px; }
  .buttons button {
    padding: .5rem 1rem;
    width: 80px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 5px; }
  .buttons button.selected {
    border: solid 1px #000;
    color: white;
    background-color: #707070; }
  .buttons button.right {
    float: right;
    margin-right: 0px;
    margin-left: 5px; }
  .buttons button.left {
    /* float:left;
   margin-right: 5px;
   margin-left: 0px;
   */ }

.title {
  font-weight: 500;
  position: relative;
  text-align: left;
  line-height: 3rem;
  font-size: 16px;
  margin-left: 40px;
  /* float: left;
  left: -50%;  or right 50% */ }

.sidebar-icon-action {
  /*  padding: 5px 8px;   */
  cursor: pointer;
  font-size: 18px;
  /* 16 */
  text-align: center;
  border: none;
  margin-top: 0.25rem;
  margin-right: 1rem;
  margin-left: 1rem; }

.sidebar-icon-action {
  /*  padding: 5px 8px;   */
  cursor: pointer;
  font-size: 18px;
  /* 16 */
  text-align: center;
  border: none; }

.sidebar-icon-open {
  margin-right: 1.25rem;
  margin-left: 0rem; }

.sidebar-icon-close {
  margin-right: 1.25rem;
  margin-left: 1.25rem; }

.sidebar-icon-action:hover {
  xbackground-color: #c0c0c0; }

.cd-today-cell .month_default_cell_inner {
  border: solid 1px #ff0000; }

.scheduler_default_cellparent {
  background-color: #f5f5f5 !important; }

.select2-container--disabled span.selection span.select2-selection {
  background-color: #eee;
  cursor: not-allowed; }

.select2-container--disabled .select2-selection__clear {
  cursor: not-allowed;
  display: none; }

.month_default_event_delete:hover, .calendar_default_event_delete:hover, .scheduler_default_event_delete:hover {
  background-color: #2ab27b !important;
  border: solid 1px #333333 !important; }

.select2-rendered__match {
  text-decoration: underline;
  color: darkred; }

.shadow-text {
  color: #fafafa; }

/*
.shadow-text {

  .select2-selection__rendered {
    color: #fafafa;
  }

}
*/
div.shadow-text .select2-container--disabled span.selection span.select2-selection {
  color: #fafafa; }

/*
span.select2-selection__clear {
  float: right;
  font-size: 16px;
  cursor: pointer;
  font-weight: 900;
  margin-right: 8px;
  margin-left: 8px;
  display: inline-block;
}
*/
.select2-dropdown .select2-results__option {
  position: relative; }

.select2-search__field.select2-search__add {
  background: #f1da916e; }
  .select2-search__field.select2-search__add :before {
    display: none; }

.select2-search:before {
  display: none; }

.select2__add {
  display: inline-block;
  right: 12px;
  min-width: 80px;
  position: absolute;
  top: 6px;
  float: right;
  font-weight: bold;
  text-decoration: underline; }

li.select2-results__option {
  cursor: default !important; }

.select2_option__red {
  color: darkred;
  display: inline-block; }

.select2_option__green {
  color: green; }

li.select2-results__option.select2-results__option--highlighted {
  color: white !important; }
  li.select2-results__option.select2-results__option--highlighted .select2_option__red {
    color: white;
    display: inline-block; }
  li.select2-results__option.select2-results__option--highlighted .select2_option__green {
    color: white;
    display: inline-block; }

.select2_patient__form {
  /*  align-items: center; */ }

.widget-body footer {
  display: block;
  padding: 7px 14px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
  margin-top: 13px;
  margin-left: -13px;
  margin-right: -13px;
  margin-bottom: -13px;
  text-align: right; }
  .widget-body footer .btn {
    float: right;
    height: 31px;
    margin: 10px 0 0 5px;
    padding: 0 22px;
    font: 300 15px/29px 'Open Sans', Helvetica, Arial, sans-serif;
    cursor: pointer; }
  .widget-body footer:after {
    content: '';
    display: table;
    clear: both; }

.widget-list-date {
  white-space: nowrap; }

.widget-list-action {
  white-space: nowrap;
  text-align: center; }
  .widget-list-action a {
    color: #b72d2d; }
  .widget-list-action a:hover {
    color: darkred; }

/*

form.form-horizontal.widget_body__form--opened {
  display: block;
}


form.form-horizontal.widget_body__form--closed {
  display: none;
}
*/
.select2_patient__form {
  display: none; }

.select2_patient__form.select2_patient__form--opened {
  display: block; }
  .select2_patient__form.select2_patient__form--opened form.form-horizontal {
    display: block !important; }

/*
form.form-horizontal.select2_patient__form--opened {
  display: none;
}
*/
/*
form.form-horizontal.widget_form__content {
 // position: relative;
 // top: -10px;
  margin: 5px 5px 5px 5px;
  background-color: #f1da916e;
//    }

}
*/
.widget-modal-content {
  display: block;
  border: solid;
  padding: 13px;
  border-radius: 5px;
  border-color: #F1DA91; }

widget-well form.form-horizontal {
  padding-top: 1rem !important; }

.select2-selection__clear, .select2-search-choice-close {
  display: block;
  position: absolute;
  right: 8px;
  font-weight: 900;
  font-size: 22px;
  cursor: pointer;
  top: -2px;
  color: #686868;
  z-index: 801; }

.select2-selection__arrow b:before, select2-container .select2-choice .select2-arrow b:before {
  content: "\2261";
  width: 100%;
  height: 100%;
  text-align: center;
  display: none;
  font-size: 34px;
  font-weight: 900;
  line-height: 0.75; }

.select2-selection__arrow, .select2-container .select2-choice .select2-arrow {
  display: inline-block;
  width: 34px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 0;
  background-clip: padding-box;
  background: #fafafa;
  display: none; }

spinner modal .modal.in.fade {
  z-index: 1060; }
  spinner modal .modal.in.fade .modal-dialog {
    width: 640px;
    margin-top: 40px; }

.scheduler_default_rowheader_inner_text {
  margin-right: 12px; }
  .scheduler_default_rowheader_inner_text a {
    font-family: "Open Sans",Arial,Helvetica,Sans-Serif;
    font-size: 14px; }

.scheduler_default_tree_image_no_children {
  display: none; }

.widget_table_wrapper table.table tbody tr.appointment:hover td {
  color: black !important;
  background-color: #F1DA91 !important; }

.widget_table_wrapper table.table tbody tr.appointment td {
  background-color: transparent; }

/*
.form-horizontal {
  .form-group {
    .form-group {
      margin-right: -62px;
    }
  }
}
*/
.modal-backdrop.popup {
  z-index: 1020; }

popup > div.modal {
  z-index: 1030; }

tr th.key:first-child, tr td.key:first-child, tr th.key:nth-child(2), tr td.key:nth-child(2) {
  display: none; }

th.key {
  color: #8a6d3b; }
  th.key i:before {
    content: "\f084"; }
  th.key i {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased; }

th.key:last-child {
  text-align: right; }
  th.key:last-child i {
    padding-right: 0.5rem; }

th.key:first-child {
  text-align: center; }

td.key:last-child {
  text-align: right; }

td.key:first-child {
  text-align: center; }

daypilot-calendar .calendar_default_main {
  /*
     div:first-child {
        div {
          div:nth-child(2) {
            div {
              div [dp-id="header"]{
                width: unset!important;
              }
            }
          }
        }
      }
      */ }
  daypilot-calendar .calendar_default_main div [dp-id="header"] {
    width: unset !important; }

daypilot-calendar .calendar_default_scroll > div > div:nth-child(2) {
  overflow-x: auto !important; }

.float-center {
  margin-left: 8px; }

div.scheduler_default_event_inner {
  white-space: pre-wrap;
  align-items: start; }

div.calendar_default_event_inner {
  white-space: pre-wrap;
  align-items: start; }

week-hours {
  /*
  .table.header_dark tfoot th {
    color: #fff;
    background-color: #696969 !important;
    border-color: #5d5d5d !important;
    height: 33px;
  }
*/ }
  week-hours .table {
    background-color: white; }
  week-hours .table > tbody > tr > td, week-hours .table > tbody > tr > th, week-hours .table > tfoot > tr > td, week-hours .table > tfoot > tr > th, week-hours .table > thead > tr > td, week-hours .table > thead > tr > th {
    padding: 8px 10px;
    height: 41px;
    vertical-align: middle;
    border-top: 0;
    font-size: 13px; }
    week-hours .table > tbody > tr > td[rowspan], week-hours .table > tbody > tr > th[rowspan], week-hours .table > tfoot > tr > td[rowspan], week-hours .table > tfoot > tr > th[rowspan], week-hours .table > thead > tr > td[rowspan], week-hours .table > thead > tr > th[rowspan] {
      border-top: solid 1px #ccc; }
    week-hours .table > tbody > tr > td.border, week-hours .table > tbody > tr > th.border, week-hours .table > tfoot > tr > td.border, week-hours .table > tfoot > tr > th.border, week-hours .table > thead > tr > td.border, week-hours .table > thead > tr > th.border {
      border-top: solid 1px #ccc; }
  week-hours .table > thead > tr > th {
    border-bottom: 0 !important;
    border-right: 0 !important;
    height: 33px;
    font-size: 12px; }
  week-hours .table {
    border: 1px solid #ccc; }
  week-hours .center {
    text-align: center; }
  week-hours .day-name {
    font-size: 14px;
    min-width: 120px;
    width: 120px; }
  week-hours .key-name {
    min-width: 8px;
    width: 8px; }
  week-hours .business-day {
    font-weight: bold;
    font-size: 14px;
    min-width: 120px;
    width: 120px; }
  week-hours input {
    width: 96px; }
  week-hours input.duration {
    text-align: center;
    background-color: #eee;
    color: #696969; }
  week-hours i.reset-icon {
    cursor: pointer;
    width: 20px;
    padding-left: 5px; }
    week-hours i.reset-icon:hover {
      color: #c79121; }
  week-hours .table > tfoot > tr > th {
    font-size: 12px;
    height: 33px; }
  week-hours .table.header_dark thead th {
    color: #4c4f53;
    background-color: #f1da90 !important;
    border-color: #5d5d5d !important; }
  week-hours .table.header_dark tfoot th {
    color: #4c4f53;
    background-color: #f1da90 !important;
    border-color: #5d5d5d !important; }

.fa.photo-spin {
  margin-top: 0.25rem;
  color: #c79121; }

dynamic-array table {
  width: 100%;
  border-collapse: collapse; }
  dynamic-array table td {
    border: solid 1px #CCC; }
    dynamic-array table td.add-row {
      height: 34px;
      padding-left: 0.45rem; }

dynamic-array input.form-control {
  border: solid 1px #ffffff; }
  dynamic-array input.form-control[disabled] {
    border: solid 1px #eee; }
  dynamic-array input.form-control:focus {
    border: solid 1px #66afe9; }

dynamic-index table {
  width: 100%;
  border-collapse: collapse; }
  dynamic-index table td {
    border: solid 1px #CCC; }
    dynamic-index table td.add-row {
      height: 34px;
      padding-left: 0.45rem; }

dynamic-index input.form-control {
  border: solid 1px #ffffff; }
  dynamic-index input.form-control[disabled] {
    border: solid 1px #eee; }
  dynamic-index input.form-control:focus {
    border: solid 1px #66afe9; }

item-input.editor label.control-label {
  text-align: left !important;
  font-size: 12px;
  width: 100% !important; }

item-input.editor > div.form-group {
  margin-bottom: 7px; }
  item-input.editor > div.form-group > div {
    width: 100%; }

item-input.editor .checkbox input[type="checkbox"].checkbox + span {
  font-size: 12px; }

/*
label input[type="checkbox"].checkbox.style-1+span:before {
  color: #333;
  border-color: #ccc;

  &:hover {
    border-color: #66afe9;
  }
}
*/
/*
label input[type="checkbox"].checkbox+span:before label input[type="checkbox"].checkbox:checked+span:before {
//  color: #333;
  &:hover {
    border-color: #66afe9 !important;
  }
}
  */
.form-horizontal dynamic .form-group {
  padding-bottom: 0;
  margin-top: 5px;
  margin-bottom: 4px;
  margin-left: 13px;
  padding-right: 4px; }

dynamic ul {
  margin-top: -8px;
  padding-bottom: 2rem;
  margin-bottom: 0; }

dynamic li {
  padding: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important; }

dynamic .glyphicon.glyphicon-remove.dynamic-ehis-remove-btn {
  position: absolute;
  top: 0;
  left: 0; }

dynamic .glyphicon.glyphicon-remove.dynamic-ehis-remove-btn:before {
  position: absolute;
  top: 0;
  margin-top: 0.25rem;
  margin-left: 0.25rem;
  color: #dd5825;
  font-size: 12px; }

dynamic li.dynamic-ehis-unselected .glyphicon.glyphicon-remove.dynamic-ehis-remove-btn:before {
  display: none; }

dynamic .glyphicon.glyphicon-remove.dynamic-remove-btn {
  top: 0;
  left: 0;
  display: inline; }

dynamic .glyphicon.glyphicon-remove.dynamic-remove-btn:before {
  position: absolute;
  display: block;
  top: 0;
  margin-top: 0.25rem;
  margin-left: 0.25rem;
  color: #dd5825;
  font-size: 12px; }

dynamic .dynamic-unselected .glyphicon.glyphicon-remove.dynamic-remove-btn:before {
  display: none; }

dynamic .dynamic-selected {
  border: 3px dotted #dd5826;
  border-radius: 0.25rem;
  padding-bottom: 0; }

dynamic .dynamic-unselected {
  border: 3px dotted #fff !important;
  border-radius: 0.25rem;
  padding-bottom: 0; }

dynamic .dynamic-remove-btn {
  cursor: pointer; }

form.form-horizontal switcher .switcher-form .form-group {
  margin-left: 0; }

switcher {
  position: fixed;
  z-index: 2;
  /*
  &.switcher-left {

   // left: 14px;
  }
  */ }
  switcher.switcher-300 > div {
    width: 300px; }
  switcher > div {
    width: 189px;
    z-index: 1038;
    top: 0;
    position: absolute;
    background: #ededed;
    opacity: 1; }
    switcher > div .switcher-form {
      display: none; }
    switcher > div.activate {
      border: solid 1px #ccc;
      padding: 5px 10px 10px; }
      switcher > div.activate > span i:before {
        content: "\f054"; }
      switcher > div.activate .switcher-form {
        display: block; }
    switcher > div > span {
      background: #ededed;
      border: solid 1px #ccc;
      position: absolute;
      top: 0;
      display: block;
      height: 30px;
      width: 30px;
      padding: 2px;
      text-align: center;
      line-height: 28px;
      cursor: pointer; }
      switcher > div > span > i.fa {
        font-size: 17px; }
    switcher > div span > i {
      font-size: 17px; }
  switcher.switcher-right > div {
    right: 0; }
    switcher.switcher-right > div > span {
      border-radius: 5px 0 0 5px; }
    switcher.switcher-right > div.activate {
      right: 0 !important;
      box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.2); }
  switcher.switcher-left > div > span {
    border-radius: 0 5px 5px 0; }
  switcher.switcher-right > div.activate > span {
    left: -30px; }
  switcher.switcher-right {
    right: 14px; }
  switcher.switcher-left > div.activate > span {
    right: -30px; }
  switcher.switcher-right > div.activate {
    box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 0 5px; }
  switcher.switcher-left > div.activate {
    box-shadow: 11px 12px 23px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px 0; }
  switcher.switcher-right > div > span i:before {
    content: "\f053"; }
  switcher.switcher-right > div.activate > span i:before {
    content: "\f054"; }
  switcher.switcher-left > div > span i:before {
    content: "\f054"; }
  switcher.switcher-left > div.activate > span i:before {
    content: "\f053"; }
  switcher.switcher-left > div {
    margin-left: -14px; }
  switcher.switcher-right > div > span {
    right: 0; }
  switcher .btn {
    font-size: 14px !important;
    line-height: 1.5 !important;
    border-radius: 3px !important;
    padding: 3px 7px !important;
    height: auto !important; }
  switcher.switcher-left > div > span {
    top: -1px; }
  switcher.switcher-right > div.activate > span {
    right: -1px;
    top: -1px;
    border-right: 0; }
  switcher.switcher-left > div.activate > span {
    right: -29px;
    top: -1px;
    border-left: 0; }

label input[type="checkbox"].checkbox + span, label input[type="radio"].radiobox + span {
  z-index: 0 !important; }

/*
switcher.switcher-left.switcher-aside > div {
  width: unset;
  position: sticky;
 // margin-top: 9rem;
  top: 9rem;
  margin-top: 12px;
  flex-shrink: 1;
}

switcher.switcher-left > div {
//  top: 9rem;
  margin-top: 12px;
}


switcher.switcher-left > div.activate {
//  top: 9rem;
  margin-top: 12px;
}

switcher.switcher-left.switcher-aside > div.activate {
  width: 189px;
  position: sticky;
 // margin-top: 9rem;
  top: 9rem;
  margin-top: 12px;
  flex-shrink: 1;
}

switcher.switcher-left.switcher-aside {
  flex-shrink: 1;
  position:sticky;
 //s margin-top: 9rem;

}
*/
switcher.switcher-aside > div {
  width: unset;
  position: sticky;
  top: 9rem;
  flex-shrink: 1; }

switcher > div {
  margin-top: 22px; }

switcher > div.activate {
  margin-top: 22px; }

switcher.switcher-aside > div.activate {
  width: 189px;
  position: sticky;
  top: 9rem;
  flex-shrink: 1; }

switcher.switcher-aside.switcher-300 > div.activate {
  width: 300px; }

switcher.switcher-aside {
  flex-shrink: 1;
  position: sticky; }

switcher.switcher-right.switcher-aside > div {
  margin-right: -13px; }

switcher.switcher-right.switcher-aside > div.activate {
  border-right: 0; }

switcher.switcher-right.switcher-aside > div > span {
  border-right: 0; }

switcher.switcher-plus > div > span i:before {
  content: "\f055"; }

switcher.switcher-plus > div > span > i.fa {
  font-size: 21px; }

switcher.switcher-plus > div.activate > span > i.fa {
  font-size: 17px; }

switcher.switcher-list > div > span i:before {
  content: "\f0cb"; }

switcher.switcher-list > div > span > i.fa {
  font-size: 21px; }

switcher.switcher-list > div.activate > span > i.fa {
  font-size: 17px; }

switcher.switcher-list > div {
  margin-top: -9rem; }

switcher.switcher-calendar > div > span i:before {
  content: "\f073"; }

switcher.switcher-calendar > div > span > i.fa {
  font-size: 21px; }

switcher.switcher-calendar > div.activate > span > i.fa {
  font-size: 17px; }

switcher.switcher-right.switcher-aside.switcher-list > div.activate {
  margin-top: -9rem; }

.dynamic-selected.gu-mirror {
  border: solid 1px #dd5826 !important;
  /*
    label {
      color: green;
      font-weight: bold;
    }
    */
  background-color: rgba(255, 0, 0, 0.2);
  transition: opacity 0.25s ease-in-out; }
  .dynamic-selected.gu-mirror > span {
    display: none; }
  .dynamic-selected.gu-mirror :not(label) {
    display: none; }

.dynamic-selected.gu-transit > span {
  display: none !important; }

.legend-title {
  text-align: center; }

div > legend {
  word-break: break-word; }

switcher.switcher-static {
  position: relative;
  top: 0; }
  switcher.switcher-static.switcher-left {
    right: 13px; }
  switcher.switcher-static.switcher-right {
    right: 0; }
  switcher.switcher-static > div {
    position: sticky;
    top: 8.5rem;
    margin-top: 0;
    margin-left: 13px; }
    switcher.switcher-static > div > span {
      display: none; }
    switcher.switcher-static > div > div.switcher-form {
      display: block;
      border: solid 1px #ccc;
      padding: 5px 10px 10px; }

user-index table {
  width: 100%;
  border-collapse: collapse; }
  user-index table td {
    border: solid 1px #CCC; }
    user-index table td.add-row {
      height: 34px;
      padding-left: 0.45rem; }
    user-index table td select5 select.select5 {
      border: 0;
      width: 100%;
      z-index: 800 !important; }
  user-index table .select2-selection, user-index table .select2-container .select2-choice {
    border: 0; }

user-index input.form-control {
  border: solid 1px #ffffff; }
  user-index input.form-control[disabled] {
    border: solid 1px #eee; }
  user-index input.form-control:focus {
    border: solid 1px #66afe9; }

.select2-search-choice-close, .select2-selection__clear {
  padding-left: 8px;
  position: relative;
  float: right; }

.select2-container--open .select2-dropdown, .select2-drop-active {
  border: 1px solid #5D98CC;
  border-top: none;
  border-bottom-width: 1px !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.select2-container-multi .select2-choices, .select2-selection--multiple {
  min-height: 34px; }

.select2-selection__choice, .select2-container-multi .select2-choices .select2-search-choice {
  line-height: 20px; }

.select2-selection__choice__remove {
  min-height: 22px; }

.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

switcher.switcher-left.switcher-routines {
  z-index: 700; }
  switcher.switcher-left.switcher-routines > div {
    width: 322px;
    margin-left: 14px;
    top: 3rem; }
  switcher.switcher-left.switcher-routines form.form-horizontal {
    padding-top: 0 !important; }
  switcher.switcher-left.switcher-routines .attention {
    color: red; }

.cd_event_menu {
  font-size: 22px;
  border: 1px solid #aaa;
  padding: 3px;
  cursor: pointer;
  background-color: red; }
  .cd_event_menu:before {
    content: "\f150" !important; }

.dropdown-badge {
  font-size: 12px !important;
  padding: 3px 7px !important;
  position: relative !important;
  top: 0 !important;
  right: 0px !important; }

checkbox-group {
  /*
  input.chk-btn:not(:checked) + label:hover {
    box-shadow: 0px 1px 3px;
  }

  input.chk-btn:not(:checked) + label:hover {
    box-shadow: 0px 1px 3px;
    border-color: #3276b1;
  }


  input.chk-btn:checked + label:hover {
//    box-shadow: 0px 1px 3px;
  }
 */
  /*
  input.chk-btn + label:active,
    input.chk-btn:checked + label {
     // box-shadow: 0px 0px 3px inset;
      background: #eee;
    }

    input.chk-btn:not(:checked) + label {
      color: #0e90d2;
      border: 1px solid #ddd;

    }
  */ }
  checkbox-group input.chk-btn {
    display: none; }
  checkbox-group input.chk-btn + label {
    border: 1px solid grey;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 5px;
    width: 3.5rem;
    text-align: center;
    margin-left: 0.25rem; }
  checkbox-group input.chk-btn:not(:checked) + label:hover {
    /* box-shadow: 0px 1px 3px; */
    border: solid 1px #3276b1; }
  checkbox-group input.chk-btn:checked + label:hover {
    /* box-shadow: 0px 1px 3px; */
    color: #f1da90; }
  checkbox-group input.chk-btn:not(:checked) + label {
    /* color: #ffffff; */
    border: 1px solid #ccc; }
  checkbox-group input.chk-btn + label:active, checkbox-group input.chk-btn:checked + label {
    /* box-shadow: 0px 0px 3px inset; */
    background: #686868;
    border: solid 1px #5d5d5d;
    color: white; }
  checkbox-group label > input.chk-btn:checked {
    /* box-shadow: 0px 0px 3px inset; */
    background: #686868;
    border: solid 1px #5d5d5d;
    color: white; }
  checkbox-group label.checkbox-label {
    border: 1px solid grey;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 5px;
    width: 3.5rem;
    text-align: center;
    margin-right: 0.25rem; }
  checkbox-group label {
    margin-bottom: 0; }
