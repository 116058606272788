/* You can add global styles to this file, and also import other style files */

/* #GOOGLE FONT */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700");

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/resource-timeline/main.css';
/*
@media screen {

       #left-panel {
        left: 0;
    }


    .hidden-menu #left-panel {
        left: -210px;
    }

}

*/

/*** LOGIN FORM ***/

.header-group {
    margin-top:1rem;
    margin-right:1rem;
}

.main-login {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.container-login {
    margin-top: -15%;
    width: 360px;
}

/***   ***/


/*
.pagination-list {

    display: inline-block;
   min-height: 1.5rem;
    border:0;

}

.pagination-list ul{
        position: absolute;
         right: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
}
*/
/*
pagination.pagination-list {
    border: 0;
}

.pagination-list .active>a.page-link {
    background-color: lightslategrey!important;

}
.pagination-list a.page-link {
    border: 0;
}
*/
/*
pagination {
    border: 0;
}
.pagination {
    border: 0;
}
*/
/*
.pagination-list.ul> li{
    border: 0;
}
*/
/*
li > a{

    border: 3px red;
}
.page-item.active > a{
    background-color: lightgrey;
}
.page-item > a{
    background-color: lightgrey;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:-5px;
}
/*
ul.pagination {

    border: 0px;
}

.page-link {
    border: 5px;
}
.page-item.active > a:hover{
    background-color: lightgrey;
}


.pagination-list a.page-link .active {
    border-color: red;
}

*/
.table-widget tbody tr {
    cursor: pointer;
}
/*
.table-widget >thead >tr>th {
    background-color: white!important;
    border-bottom: 0;
}
*/
 .table-widget {
    background-color: white!important;
    border-bottom: 0;

}

div.widget-footer.table-widget {
    padding: 1rem;

}

.table-widget-bordered {
    background-color: white!important;
    border-bottom-width: 1px!important;

}
.table-widget-bordered tbody tr {
    cursor: pointer;
}

div.widget-footer.table-widget-bordered {
    padding: 1rem;

}

.fa-blank
{
    visibility:hidden !important;
}


/*****************************************************************
    OVERRIDE MAIN LAYOUT
******************************************************************/

#left-panel {
    padding-top: 0px!important;
    /* overflow-x: scroll; */
}

/* width of left panel */

aside {
    width: 220px; /* 200px; */
}
#main {
    margin-left: 220px; /* 200px; */
}

/* fixed navigation */

.fixed-navigation nav>ul {
    padding-right: 0;
    width: 100%;
    height: 90%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.otpCode {
    vertical-align: baseline;
    height: 16px;
    cursor: pointer;
}

.otlChkLabel {
    padding: 0px 0 0 6px;
    vertical-align: super;
    font-size: 13px;
    color: #3276b1;
    cursor: pointer;
}

/* mimify button */

.minifyme {
    padding-top: 4px;
}

.minified .minifyme {
    padding-top: 5px;
}

/* Panel "Layout settings" */

.demo {
    top: 0;
}
/* logo */


.minified .login-info img {
    display: none;
}

.smart-style-1 .login-info {
    height: 39px; /* height: auto; */
}
.smart-style-2 .login-info {
   height: 40px;
}

.smart-style-2 #left-panel {
     padding-right: 0px!important;
    /* padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 0px;
    */
}

.smart-style-2.minified #left-panel {
    padding: 0 !important;
    padding-top: 0!important;
}

.smart-style-2 nav ul li.active>a:before {
    color: #bfbfbf;
}

/* logout button */

#logout {
    margin-right: 22px;
    margin-top: -5px;
}

.smart-style-1 .btn-header>:first-child>a {
    background: #333;
    border: 1px solid #f4f4f4;
    color: #fff !important;
}

.smart-style-2 .btn-header>:first-child>a:hover {
    border: 1px solid #bfbfbf;
    color: #222;
 /*   transition: all 0.0s; */
    cursor: pointer;
    -webkit-box-shadow: inset 0px 0px 4px 1px rgba(0,0,0,0.08);
    box-shadow: inset 0px 0px 4px 1px rgba(0,0,0,0.08);
}

.smart-style-2 .btn-header>:first-child>a:hover{
     background: #eee;
    color: #404040!important;
}

.smart-style-1 .btn-header>:first-child>a{
    background: #000;
    color: #999!important;
}

.smart-style-1 .btn-header>:first-child>a:hover{

    color: #fff!important;
}

/*  logo-info */

.logo-info {
    /* height: 39px; */ /* height: auto; */
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.smart-style-0 .logo-info {
    /*height: 39px;*/
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.smart-style-1 .logo-info {
    /*height: 39px;*/
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.smart-style-2 .logo-info {
    margin-left: -15px;
    height: 40px;
}

.smart-style-2.minified .logo-info {
    margin-left: 0px;
}


.minified .logo-info-lg {
    display:none;
}

.logo-info-sm {
    display: none;
}

.minified .logo-info-sm {
    display: /* block; */  none;
}



.widget-title {
    height: 100%;
    width: auto;
    display: inline-block;
    font-size: 14px;
    position: relative;
    margin: 0;
    line-height: 34px;
    font-weight: 700;  /* normal; */
    letter-spacing: 0px;
    margin-left: 10px !important;
    float: left;
}

.sign-in-text {
    margin-left: 2rem;
    font-size: 3rem;
    font-weight: 900;
    color: #1094d1;
    vertical-align: middle;
}

.sign-in-text1 {
    /* margin-left: 3.5rem; */
    font-size: 2.5rem;
    font-weight: 900;
    color: #1094d1;
    vertical-align: middle;

}

/*   TABS */

.tabs-left>.nav-tabs>li{
    margin-left: 2px;
}

.tabs-left .nav-tabs>li.active>a {
   box-shadow: -2px 0 0 #3276b1;
 }

.tabs-top>.nav-tabs>li{
    margin-top: 2px;

}

.tabs-top{
    margin-left: 2px;
}

.nav-tabs>li.active>a {
    box-shadow: 0 -2px 0 #3276b1;
}

.tabs-none {
    display: none;
}

/*  */

.smart-form  legend {  /* for the nexy checkbox ? */

    margin-bottom:2rem;
}
/*
.btn.btn-ribbon {
    line-height: 24px;
    width: 24px;
}
*/


.smart-style-0 .btn.btn-ribbon:hover{
    background: #979491;
}
.smart-style-1 .btn.btn-ribbon:hover{
    background: #1b1e24;
}
.smart-style-2 .btn.btn-ribbon:hover{
    background: #444;
}


.logo-info {
    text-align: center;
}

.logo-text {
    font-size: 17px;
    font-weight: 500;
    color: #69737a;
    line-height: 40px;

}

.smart-style-0 .logo-text{
    color: #c0bbb7;
}

.smart-style-1 .logo-text{
    color: #8b91a0;
}


.smart-style-2 .logo-text{

}


.int-number {
    text-align: right;
}

.pointer {
    text-align: center;
    width: 18px;

}

.currency-number {
    text-align: right;
}

/* widget z-index- 1050 */

#jarviswidget-fullscreen-mode {
    z-index: 1039!important;
}
.spinner-message-container {
    z-index: 1041!important; /* 10001; */
}

.ui-datepicker { z-index: 99999!important; }

.price-table {
    border-bottom:  1px solid #ddd;
}

.price {
    text-align: right;


}
.price:before {
 content: "$" !important;
}

td.td-input {
    padding-top: 4px!important;
    padding-bottom: 0!important;
}

td.td-input-4 {
    padding-top: 3px!important;
    padding-bottom: 3px!important;
    border:0!important;
}

.text-color-danger {
    color: red;
}

/* select 2 */

.select2-container-multi .select2-choices, .select2-selection--multiple{
    border-color: #ccc; /*#eee;*/ /* #3276b1!important; */
    margin-top: -2px;

    border-radius: 3px;  /* sp++ */

}

.select2-selection__choice, .select2-container-multi .select2-choices .select2-search-choice {
    background-color: #686868;
    border: 1px solid #5d5d5d; /* #333; */
/*    padding: 1px 28px 1px 8px;
    margin: 4px 0px 3px 5px;
*/
    border-radius: 3px;  /* sp++ */


}

.select2-results__option--highlighted, .select2-results .select2-highlighted {
    background: #686868; /* #3276b1; */
    color: #fff;
}

.select2-form .select2-selection__choice, .select2-container-multi .select2-choices .select2-search-choice  {
    background-color: #686868;
    border: 1px solid #333;
    padding: 1px 28px 1px 8px;
    margin: 4px 4px 3px 5px;

}

.select2-search {
    z-index: 980;
}

/*.select2-form  select2-selection__choice__remove::before { */
.select2-form  .select2-selection__choice__remove::before {
    padding: 2px 4px 3px 3px;
    box-sizing: border-box;
}



.table-key-header {
    width: 7rem;
}


.btn-input {
    height: 32px!important;
    width: 32px!important;
}

em.invalid {
    display: block;
    margin-top: 2px!important;
    padding: 0 0px;

    color: #D56161;
    font-weight: 400!important;
    font-style: italic!important;
    line-height: 16px;
}

.smart-form .state-error+em {
    display: block;
    margin-top: 2px!important;
    padding: 0 0px;
    font-style: italic;
    font-size: 13px;
    line-height: 16px;
    color: #D56161;
}

/***********************************/
/**          Dragula.css          **/
/***********************************/
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
}
.gu-hide {
    display: none !important;
}
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 1; /* 0.2; */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.country {
    margin-bottom: 10px;
}

.ConnectButton {
    background: #635bff;
    display: inline-block;
    text-decoration: none;
    border-radius: 4px;
    padding: 10px 15px;
    color: #fff;
}
.ConnectButton:hover {
    background: #7a73ff;
    color: #fff;
}
.ConnectButton span b {
    padding-left: 5px;
    font-style: italic;
}
      /*
      .dynamyc-ehis-container > li.dynamic-ehis-unselected,
      .gu-mirror {
      //  margin: 10px;
      //  padding-left: 10px;
      //  padding-top: 10px;
      //  padding-right: -20px;
      //  background-color: rgba(0, 0, 0, 0.2);
      //  transition: opacity 0.4s ease-in-out;
        border: 3px dotted rgba(0, 0, 0, 0.2);
        border-radius: $border-radius;
        transition: opacity 0.25s ease-in-out;
      }

      .dynamyc-ehis-container > li.dynamic-ehis-selected,
      .gu-mirror {
        //  margin: 10px;
        //  padding-left: 10px;
        //  padding-top: 10px;
        //  padding-right: -20px;
        //  background-color: rgba(0, 0, 0, 0.2);
        //  transition: opacity 0.4s ease-in-out;
      //  border: 3px dotted rgba(0, 0, 0, 0.2);
      //  border-radius: $border-radius;
        transition: opacity 0.25s ease-in-out;
      }
      */
/*
  .dynamyc-ehis-container > li,
  .gu-mirror {
      background-color: rgba(0, 0, 0, 0.2);

      transition: opacity 0.25s ease-in-out;
  }
  */

/*
.container >div {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}


.gu-mirror {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
*/

/***********************************/
/**         end of  dragula.css   **/
/***********************************/


